<template>
  <v-form ref="formOrdine" v-model="validFormOrdine">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex xs12 lg6>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select
                label="Cliente"
                :items="clienti"
                item-text="ragione_sociale"
                item-value="cliente_id"
                v-model="ordine.cliente_id"
                :disabled="!!ordine._id || readOnly()"
                :rules="[$rules.required]"
                @input="changedCliente"
                required />
              <v-select
                label="Opera"
                :items="opere"
                item-text="opera"
                item-value="opera_id"
                v-model="ordine.opera_id"
                :disabled="!ordine.cliente_id || readOnly()"
                :rules="[$rules.required]"
                @input="changedOpera"
                required />
              <v-select
                label="Prodotto"
                :items="prodotti"
                item-text="prodotto"
                item-value="prodotto_id"
                v-model="ordine.prodotto_id"
                :disabled="!ordine.opera_id || readOnly()"
                @input="changedProdotto" />
              <date-picker
                v-model="ordine.consegna_da"
                :readonly="readOnly()"
                label="Consegna da"
                name="consegna_da" />
              <date-picker
                v-model="ordine.consegna_a"
                :readonly="readOnly()"
                label="Consegna a"
                name="consegna_a" />
              <v-text-field
                label="Peso per copia (in grammi)"
                v-model="ordine.peso"
                name="peso"
                :disabled="readOnly()"
                :rules="[$rules.numeric, $rules.maxlength(5)]" />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 lg6>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                label="Codice finito"
                v-model="ordine.codice_finito"
                :disabled="readOnly()"
                name="codice_finito"
                :rules="[$rules.maxlength(30)]" />
              <v-text-field
                label="Numero"
                v-model="ordine.numero"
                name="numero"
                :disabled="readOnly()"
                :rules="[$rules.maxlength(20)]" />
              <v-text-field
                label="Descrizione"
                v-model="ordine.descrizione"
                name="descrizione"
                :disabled="readOnly()"
                :rules="[$rules.maxlength(200)]" />
              <v-text-field
                label="Tipo lavorazione"
                v-model="ordine.tipo_lavorazione"
                name="tipo_lavorazione"
                :disabled="readOnly()"
                :rules="[$rules.maxlength(500)]" />
              <v-select
                label="Stato"
                :items="stati"
                item-text="nome"
                item-value="id"
                v-model="ordine.stato"
                :disabled="readOnly()" />
              <v-textarea
                auto-grow
                label="Note"
                name="note"
                v-model="ordine.note"
                :disabled="readOnly()"
                :rules="[$rules.maxlength(4000)]" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex>
          <v-btn
            class="ml-0"
            color="primary"
            :disabled="!validFormOrdine || readOnly()"
            id="salvaOrdine"
            @click.native="save">
            Salva
          </v-btn>
          <v-btn
            v-if="ordine._id"
            @click.native="duplica">
            Duplica
          </v-btn>
          <v-btn
            class="ml-0"
            color="error"
            v-if="ordine._id && !ordine.has_ddt"
            :disabled="readOnly()"
            @click.native="elimina">
            Elimina
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { $EventBus } from '@/eventBus'
import datePicker from '@/components/common/DatePicker'

export default {
  props: {
    ordine: Object
  },
  components: {
    datePicker
  },
  data: () => ({
    stati: [{ id: 0, nome: 'Non ancora prodotto' }, { id: 1, nome: 'In produzione' }, { id: 2, nome: 'Consegnato' }],
    validFormOrdine: false,
    clienti: [],
    opere: [],
    prodotti: []
  }),
  methods: {
    getClienti (ordineId) {
      this.$plsqlMethod('ordine', 'get_clienti', { ordine_id: ordineId })
        .then(response => {
          this.clienti = response.data
          if (this.ordine._id) {
            let idc = this.ordine.cliente_id
            let ido = this.ordine.opera_id
            let cliente = this.clienti.find(function (c) { return c.cliente_id === idc })
            this.opere = cliente.opere
            let opera = this.opere.find(function (c) { return c.opera_id === ido })
            this.prodotti = opera.prodotti
          }
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit({ type: 'error', text: 'Errore lettura Clienti' })
        })
    },
    changedCliente () {
      if (!this.ordine._id) {
        let idc = this.ordine.cliente_id
        let cliente = this.clienti.find(function (c) { return c.cliente_id === idc })
        this.opere = cliente.opere
        if (this.opere.length === 1) {
          this.ordine.opera_id = this.opere[0].opera_id
          this.changedOpera()
        } else {
          delete this.ordine.opera_id
          delete this.ordine.prodotto_id
          delete this.ordine.codice_finito
        }
      }
    },
    changedOpera () {
      delete this.ordine.prodotto_id
      let id = this.ordine.opera_id
      let opera = this.opere.find(function (c) { return c.opera_id === id })
      this.prodotti = opera.prodotti
      if (!this.ordine._id) this.ordine.codice_finito = opera.codice
      if (opera.prodotti.length === 1) {
        this.ordine.prodotto_id = opera.prodotti[0].prodotto_id
        this.changedProdotto()
      }
    },
    changedProdotto () {
      let id = this.ordine.prodotto_id
      let ido = this.ordine.opera_id
      let opera = this.opere.find(function (c) { return c.opera_id === ido })
      if (!this.ordine._id) {
        this.ordine.codice_finito = opera.codice + this.prodotti.find(function (c) { return c.prodotto_id === id }).codice
      }
    },
    duplica () {
      this.$plsqlMethod('ordine', 'duplica', { id: this.ordine._id })
        .then(response => {
          $EventBus.$emit('message', { type: 'success', text: 'Ordine duplicato' })
          this.$router.push({ name: 'ordini' })
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    elimina () {
      this.$plsqlDelete('ordine', this.ordine._id)
        .then(response => {
          $EventBus.$emit('message', { type: 'success', text: 'Ordine eliminato' })
          this.$router.push({ name: 'ordini' })
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    readOnly () {
      return (this.ordine._id && !this.ordine.non_spedito)
    },
    save () {
      if (this.$refs.formOrdine.validate() && !this.readOnly()) {
        this.$plsqlSave('ordine', this.ordine)
          .then(response => {
            $EventBus.$emit('message', { type: 'success', text: 'Ordine salvato' })
            if (this.$route.params.id === 'add') {
              $EventBus.$emit('ordine:added', response.data)
            }
          })
          .catch(err => {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          })
      }
    }
  },
  mounted () {
    // this.getClienti()
  }
}
</script>
